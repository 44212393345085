import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { makeSelectRole } from "../lib/providers/auth-provider/";
import connect from "react-redux/es/connect/connect";
import { logout } from "../lib/providers/auth-provider/";
import MasterPageAdmin from "../shared-components/MasterPageAdmin";

const asyncComponent = (importComponent, accesList) => {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
      this._isMounted = false;
    }

    async UNSAFE_componentWillMount() {
      this._isMounted = true;
      const { default: component } = await importComponent();

      if (this._isMounted) {
        this.setState({
          component: component,
        });
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const PageComponent = this.state.component;
      const isAllowed = accesList && accesList.whitelist.indexOf(this.props.role) !== -1;

      if (!isAllowed) {
        const newPath = accesList[this.props.role]
          ? accesList[this.props.role]
          : "/login";

        return (
          <Redirect
            push={true}
            to={{
              pathname: newPath,
              state: { previousPath: this.props.location.pathname },
            }}
          />
        );
      }

      if (PageComponent) {
        return <PageComponent logout={this.props.logout} {...this.props} />;
      }

      switch (this.props.role) {
        case "admin":
          return (
            <MasterPageAdmin logout={this.props.logout}>
              <div style={{ textAlign: "center" }}></div>
            </MasterPageAdmin>
          );
        default:
          return <div />;
      }
    }
  }

  const mapStateToProps = (state) => ({
    role: makeSelectRole()(state),
  });

  const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
  });

  return connect(mapStateToProps, mapDispatchToProps)(AsyncComponent);
};

export default asyncComponent;
