import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";

addLocaleData([...en]);

/*
Before adding new keys here, add a key mapping in the keys.json file in this directory for
intel and abstraction purposes.
*/
const messages = {
  //  PRESCRIBER DASHBOARD
  pdf_load_error: "Error loading PDF",
  stats_info: "This value corresponds to the change from previous month",
  no_results_found: "No results found",
  not_started: "Not started",
  error_title: "Sorry, an error occurred",
  resend_prescription: "Resend Prescription",
  prescription: "Prescription",
  send_results_after_completion: "Send results to Patient by email after completion",
  report_unavailable: "This Report is not available",
  report_unavailable_description:
    "The patient can unlock it following the link available in the email with the Reports",
  send_invite_text: "Do you want to resend this invite?",
  resend: "Resend",
  invite_success: "Invite was sent successfully!",
  send_results: "Send Results",
  send_results_text: "Do you want to send the results to the Patient?",
  send_results_success: "Results sent successfully!",
  view_results: "View Results",
  change_chart: "Change Chart",
  choose_report: "Choose Report",
  report_not_requested: "This report was not requested. Unlock it for {price}€",
  confirmed: "Confirmed",
  questionnaires: "Questionnaires",
  monitor: "Monitor",
  prescribe_analysis: "Prescribe",
  subscription: "Subscription",
  pd_completed: "Completed",
  invite_failed: "Invite Failed",
  failed: "Failed",
  english_language: "English",
  portuguese_language: "Portuguese",
  french_language: "French",
  erorr_title: "Sorry, an error occurred",
  error_description: "Please try again. If the problem persists, contact support.",
  select_reports: "Select Reports",
  choose_option: "Choose one option",
  patient_paid: "Paid by the Patient",
  patient_paid_description: "The patient will receive an email.",
  pre_paid: "Pre-paid",
  pre_paid_description: "The amount will be paid now.",
  both_reports: "Both Reports",
  reports_and_payment: "Reports and payment",
  prescription_successful: "SAYN Analysis was successfully prescribed",
  patient_will_receive_email:
    "Well done. Your patient will receive a follow-up email to fulfill the analysis.\nTo keep up with the process, just check the Monitor.",
  completed_analysis: "Completed Analysis",
  prescriptions_sent: "Prescriptions Sent",
  total_patients: "Total Patients",
  log_out: "Logout",
  overview: "Overview",
  dashboard: "Dashboard",
  my_profile: "My Profile",
  sayn_description:
    "SAYN Analysis is the first diagnosis resource able to decode your patients organic energy, to help apply and adapt any treatment, promoting an efficient outcome and the best results.",
  assistance_email: "If you need any assistance, please send an email to ",
  need_help: "Need help?",
  check_documentation: "Consult our guide to find the answers you’re looking for",
  documentation: "Sayn Guide",
  completion: "Completion",
  prescription_date: "Prescription Date",
  waiting: "Waiting",
  pd_add_observations: "Add Observations",
  pd_save: "Save",
  search_patient: "Search Patient",
  rows_per_page: "Rows per page",
  patients: "Patients",
  email: "Email",
  back: "Back",
  patient_information: "Patient Information",
  new_patient: "New Patient",
  setup_patient_information: "Setup patient information",
  existing_patient: "Existing Patient",
  select_from_patients: "Select from your list of Patients",
  complete_information: "Complete the Information",
  enter_first_name: "Enter patient first name",
  enter_last_name: "Enter patient last name",
  enter_email: "Enter patient email",
  enter_notes:
    "If you like to customize your message to your patient and add some remarks, please do so here",
  sd_next: "Next",
  // OTHER
  allow_prescriber: "Allow your Prescriber to see this report",
  report_payment_message:
    "Your payment was successful! The report will be sent to your email.",
  already_paid: "This payment was already made.",
  questionnaire_message: "Questionnaire initial message",
  both: "Both",
  paid: "Paid",
  not_paid: "Not Paid",
  reports_paid: "Reports Paid",
  report_link: "Report (w/ payment link)",
  report_both: "Report(s)",
  link_in_button_error: "Cannot insert link inside a button",
  image_delete: "Are you sure you want to delete this image?",
  image_delete_error:
    "This image cannot be deleted because it is being used elsewhere ({entity}). Please remove it from there first, if you want to delete it.",
  delete_success: "{entity} deleted successfully!",
  question_image: "Question",
  insert_url: "Enter the URL of the link:",
  signup: "Sign Up",
  download: "Download",
  preview: "Preview",
  variables: "Variables",
  buttons: "Buttons",
  gallery: "Gallery",
  enlarge: "Enlarge",
  add_image: "Add this Image",
  edit_text: "Edit Text",
  custom_texts: "Custom Texts",
  seasonal_reminder: "Seasonal Reminder",
  prescriber_message: "Prescriber Message",
  template_error: "All the Variables and Buttons in the list should be used!",
  email_template_saved: "Email Template saved",
  custom_text_saved: "Custom Text saved",
  template: "Template",
  edit_template: "Edit Template",
  email_templates: "Email Templates",
  email_subject: "Email Subject",
  existing_pacient: "Existing Pacient",
  new_pacient: "New Pacient",
  send_reminder: "Send Seasonal Reminder",
  not_send: "Do not send",
  user_name: "Name",
  user_surname: "Surname",
  job: "Job",
  birth: "Birth",
  portuguese: "Portuguese",
  french: "French",
  default_questionnaire_price: "Default price for SAYN1 + SAYN2 (in cents)",
  default_sayn1_price: "Default price for SAYN1 (in cents)",
  default_sayn2_price: "Default price for SAYN2 (in cents)",
  error_prepay_remote:
    "An error occurred while processing your request. Please contact us directly.",
  max_observation_size: "The maximum number of characters is {max}",
  add_observations: "Add Observations",
  observations_saved: "Observations saved!",
  values_saved: "Values saved!",
  block_report: "Prevent Report from being sent",
  report_sent: "Report sent",
  sent: "Sent",
  not_sent: "Not sent",
  post_questionnaire_delay: "Post-Questionnaire delay (in hours)",
  backoffice_values: "Editable Values",
  go_back: "Go Back",
  observations: "Observations",
  observations_recommendations: "Observations / Recommendations",
  all_rights_reserved: "All rights reserved",
  amplitude: "Amplitude",
  next: "Next",
  loading: "Loading",
  search: "Search",
  reset: "Reset",
  required: "Required",
  start: "Start",
  pacient: "Pacient",
  prescriber: "Prescriber",
  no_prescriber: "no prescriber",
  notes: "Notes",
  general_definitions: "General Definition",
  help: "Help",
  privacy_policy: "Privacy Policy",
  copyright: "copyright © 2019 Sensor",
  date: "Date",
  cancel: "Cancel",
  save: "Save",
  other: "Other",
  create: "Create",
  upload: "Upload",
  edit: "Edit",
  are_you_sure: "Are you sure?",
  yes: "Yes",
  no: "No",
  price: "Price",
  total_price: "Total Price",
  language: "Language",
  message: "Message",
  cta_questionnaire: "Responder ao Questionário",
  submit: "Submit",
  created_at: "Created at",
  updated_at: "Updated at",
  read_at: "Read at",
  view: "View",
  delete: "Delete",
  answerOptions: "Answer Options",
  dataBase: "Data Base",
  ACTIVE: "Active",
  DISABLED: "Disabled",
  created_by: "Created by",
  actions: "Actions",
  answers: "Answers",
  completed: "Completed",
  not_completed: "Incompleted",
  questionnaire: "Questionnaire",
  questions: "Questions",
  open: "Open",
  multiple: "Multiple",
  text: "Text",
  closed: "Closed",
  close_window: "You can close this window now.",
  single: "Single",
  usersHeader: "Users",
  history: "History",
  answerQuestionnaire: "Answer Questionnaire",
  status: "Status",
  items: "Items",
  user: "User",
  up: "Up",
  down: "Down",
  ok: "OK",
  criteria: "Criteria",
  criteriaUsage: "Criteria Usage",
  metaSkill: "Meta Skills",
  principle: "Principles",
  code: "Code",
  name: "Name",
  question: "Question",
  answer: "Answer",
  all: "All",
  meta_competences: "Meta Competences",
  confirm: "Confirm",
  return: "Return",
  invites: "Invitations",
  invite: "Invite",
  send: "Send",
  elements: "Elements",
  attributes: "Attributes",
  energy_flow: "Energy Flow",
  sending: "Sending",
  feelings: "Feelings",
  organs: "Organs",
  outputs: "Outputs",
  output_metadata: "Output Metadata",
  input_metadata: "Input Metadata",
  metadata_bank: "Metadata Bank",
  meta_data: "Meta Data",
  inputs: "Inputs",
  image_metadata: "Image Metadata",
  image: "Image",
  output_information: "Output Information",
  none: "None",
  add_input: "Create New Input",
  add_output: "Create New Output",
  input_name: "Input Name",
  input_content: "Input Content",
  type: "Type",
  output_content: "Output Content",
  output_name: "Output Name",
  change_type: "Change Type",
  input_information: "Input Information",
  dowload_excel_users: "Export Excel",
  excel_users_title: "Users_List_",
  printPDF: "Print PDF",
  element_attributes: "Element's Attributes",
  attributes_bank: "Attributes Bank",
  sense: "Senses",
  duplicate: "Duplicate",
  natural_elements: "Natural Elements",
  add_natural_element: "Create New Natural Element",
  create_new_natural_element: "Create New Natural Element",
  edit_natural_element: "Edit Natural Element",
  add_season: "Create New Season",
  create_new_season: "Create New Season",
  edit_season: "Edit Season",
  season: "Season",
  add_character: "Create New Character",
  create_new_character: "Create New Character",
  edit_character: "Edit Character",
  character: "Characters",
  basic_settings: "Basic Settings",
  security_settings: "Security Settings",
  first_name: "First Name",
  last_name: "Last Name",
  birth_place: "Birth Place",
  job_name: "Job Name",
  nationality: "Nationality",
  gender: "Gender",
  birth_date: "Birth Date",
  old_password: "Old Password",
  new_password: "Choose a new password",
  choose_password: "Choose a password",
  password: "Password",
  confirm_email: "Confirm Email",
  confirm_password: "Confirm Password",
  change: "Change",
  profile: "User Profile",
  male: "Male",
  female: "Female",
  change_password: "Change Password",
  invalid_password: "Invalid Password",
  password_changed: "Your password was changed",
  required_password: "Please input your password!",
  match_password: "The two passwords that you entered do not match!",
  password_recovery: "Password Recovery",
  password_recovery_msg:
    "An email has been sent with a link to change your password. Please check your inbox.",
  match_email: "The two emails that you entered do not match!",
  input_email: "Please input your Email!",
  invalid_email: "Invalid Email",
  profile_updated: "Profile Updated",
  profile_updated_message: "Your profile was updated",
  creation_date: "Creation Date",
  no_information: "No Information",
  manage_question_criteria: "Manage Question's Criterias",
  logout: "Logout",
  login: "Log In",
  login_success: "Login successful!",
  register: "Register",
  register_success: "Registration successful!",
  auth_questionnaire_msg:
    "You will be redirected to the Questionnaire. \nYou will receive a link in your email, in case you want to come back later. \nClick OK to continue.",
  title: "Title",
  users: "Users",
  new_notification: "New Notification",
  send_notification_to: "Send Notification To",
  all_users: "All Users",
  some_users: "Some Users",
  dynamic_keys: "Dynamic Keys",
  confirm_message_disable: "Would you like to disable",
  confirm_message_enable: "Would you like to enable",
  confirm_this_male: "this",
  confirm_this_female: "this",
  enable: "enable",
  disable: "disable",
  answer_option: "answer option",
  text_answer_placeholder: "Write your answer here",
  one_criteria: "criteria",
  one_criteria_usage: "criteria usage",
  one_meta_skill: "meta skill",
  one_principle: "principle",
  resending_invitation: "Resending Invitation",
  resending_invitation_to: "Resending invitation to",
  to_fill_questionnaire: "to fill the questionnaire",
  clipboard: "Copy Invitation Code",
  save_changes: "Save Changes",
  discard_changes: "Discard changes",
  mobile_answer_component: "Components",
  one_mobile_answer_component: "Mobile Answer Component",
  add_mobile_answer_component: "Create New Component",
  create_new_mobile_answer_component: "Create New Component",
  edit_mobile_answer_component: "Edit Component",
  disable_mobile_answer_component: "Disable Component",
  enable_mobile_answer_component: "Enable Component",
  general: "General",
  algorithm_values: "Algorithm Values",
  skill_anchor: "Leveraging skills",
  skill_compensated: "Compensation Skill",
  skill_decompensated: "Decompensation Skill",
  criteria_management: "Criteria Management",
  free_in_app: "Free in APP",
  add_criteria: "Add New Criteria",
  edit_criteria: "Edit Criteria",
  free: "Free",
  initial_date: "Initial Date",
  expire_date: "Expire Date",
  description: "Description",
  entities_assigned: "Entities Assigned",
  entities: "Entities",
  effect_type: "Effect Type",
  effect: "Effects",
  create_new_highlight_output: "Create New Highlight Output",
  create_new_highlight_question: "Create New Highlight Question",
  highlight_type: "Highlight Types",
  create_new_highlight_type: "Create New Highlight Type",
  link_android: "Link Android",
  link_ios: "Link IOS",
  mobile_apps: "Mobile Apps",
  text_config: "Text Config",
  assign_entities: "Assign Entities",
  disable_text_config: "Disable Text Config",
  enable_text_config: "Enable Text Config",
  env: "Environment Variables",
  add_env: "Create New Environment Variable",
  daily_challenge: "Daily Challenge",
  number_of_inputs: "Number of Inputs",
  min_days_of_interval: "Min Days of Interval",
  add_highlight_type: "Add Highlight Type",
  daily_challenge_saved: "Daily Challenge Saved",
  create_new_highlight_questionnaire: "Create New Highlight Questionnaire",
  edit_highlight_questionnaire: "Edit Highlight Questionnaire",
  edit_highlight_question: "Edit Highlight Question",
  type_image: "IMAGE",
  type_text: "TEXT",
  edit_question: "Edit Question",
  clean_filters: "Reset Filters",
  static_string: "Static Strings",
  add_static_string: "Create New Static String",
  string_value: "String Value",
  add_feeling: "Create New Feeling",
  edit_feeling: "Edit Feeling",
  add_organ: "Create New Organ",
  edit_organ: "Edit Organ",
  input_translation: "Please, input all translations",
  select_answer_type: "Please, select the answer type",
  set_criteria_as_free: "Set Criteria as Free",
  remove_criteria_as_free: "Remove Criteria as Free",
  confirm_set_free: "Would you like to set this Criteria as free?",
  confirm_remove_free: "Would you like to remove this Criteria as free?",
  free_criterias: "Free Criteria",
  confirm_set_question_non_required:
    "Would you like to set this question as non-required ?",
  confirm_set_question_required: "Would you like to set this question as required ?",
  set_non_required: "Set question as non-required",
  set_required: "Set question as required",
  duplicate_questionnaire: "The Questionnaire has been duplicated",
  add: "Add",
  update_questionnaire: "The Questionnaire has been updated",
  add_new_app: "Add New App",
  translate: "Translate",
  delete_general_output:
    "This output cannot be deleted because it is being used in the APP",
  alternative_report: "Alterative Report",
  edit_static_string: "Edit Static String",
  edit_rich_text: "Edit Rich Text",
  add_rich_text: "Create New Rich Text",
  plain_text: "Plain Text",
  rich_text: "Rich Text",
  create_new_answer_option: "Create New Answer Option",
  edit_answer_option: "Edit Answer Option",
  relevant: "Relevant",
  irrelevant: "Irrelevant",
  enable_criteria: "Enable Criteria",
  disable_criteria: "Disable Criteria",
  set_criteria_as_relevant: "Set Criteria as Relevant",
  remove_criteria_as_relevant: "Remove Criteria as Relevant",
  confirm_set_relevant: "Would you like to set this Criteria as Relevant?",
  confirm_remove_relevant: "Would you like to remove this Criteria as Relevant?",
  relevant_criteria: "Relevant Criteria",
  surname: "Surname",
  role: "Role",
  create_user: "Create User",
  edit_user: "Edit User",
  week: "Week",
  sunday: "Sunday",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  add_week: "Add Week",
  general_insights: "General Insights",
  resources_of_focus_insights: "Resources of Focus Insights",
  act_quiz_call_to_action: "Act Quiz Call to Action",
  general_criteria: "General Criteria",
  self_criteria: "Self Criteria",
  social_criteria: "Social Criteria",
  professional_criteria: "Professional Criteria",
  report_and_react: "Report and React",
  heart_re_question: "Heart Re Question",
  highlight_schedule: "Highlight Schedule",
  delete_week: "Delete week",
  next_question: "Next Question",
  previous_question: "Previous Question",
  done: "Submit Questionnaire",
  answer_saved: "Answer has been saved",
  import: "Import",
  export: "Export",
  import_data: "Import Data",
  file_imported: "File Exported",
  data_exported: "Data Exported",
  error_exporting_data: "Error Exporting Data",
  invalid_questionnaire_email: "The email is not correct, please check it again",
  page_not_found: "Page not found",
  error_general: "An error occured, please try again later",
  pay: "Pay",
  payment: "Payment",
  payment_succeeded: "Payment Succeeded",
  payment_unsuccessful: "Payment Unsuccessful. Please try again.",
  payment_processing: "Your payment is processing.",
  pre_pay_invite: "Pre-Pay Invite",
  pre_pay_invite_msg: "Who do you want to send the invitation?",
  error: "Error",
  export_data: "Export Data",
  invitation: "Invitation",
  disable_invitation: "Disable Invitation",
  enable_invitation: "Enable Invitation",
  algorithm_config: "Algorithm Config",
  user_evolution: "User Evolution",
  no_data: "No Data",
  answer_type: "Answer Type",
  ADMIN: "Admin",
  CONSULTANT: "Consultant",
  PRESCRIBER: "Prescriber",
  PACIENT: "Pacient",
  add_prescriber: "Add/Change Prescriber",
  start_date: "Start date",
  end_date: "End Date",
  field_required: "Field Required",
  report: "Report",
  select_date: "Select date",
  summary_of_resources: "Summary of resources",
  sensorial_rhythms: "Sensorial Rhythms",
  type_to_search: "Type to search",
  confirm_invitation: "Are you sure you want to send this invitation?",
  confirm_answer: "Are you sure you want to continue?",
  confirm_prepayment: "Are you sure you want to prepay this questionnaire?",
  free_invite: "Select this option to make the questionnaire free",
  sending_email_to: "Sending email to",
  sending_email: "Sending email...",
  to_fill_the_questionnaire: "to fill the questionnaire",
  sending_invitation: "Sending Invitation",
  invitation_sent: "Invitation sent",
  an_email_was_sent_to: "An email was sent to",
  payment_page_title:
    "Analyzing your Sensory Activity is essential for taking care of your health",
  payment_page_list_title: "By analyzing your Sensory Activity, you are enabling:",
  payment_page_list_item_one:
    "a treatment adjusted to your physiological and biological activity;",
  payment_page_list_item_two:
    "an informed adaptation of recommendations and prescriptions;",
  payment_page_list_item_three:
    "an organically successful reaction to the health care you need.",
  questionnaire_welcome_page_title: "Welcome to your Sensorial System",
  questionnaire_welcome_page_right_paragraph_one:
    "Just like a blood test, the SAYN test will show exactly what your body is evidently telling you about itself, even if you're not aware of it.",
  questionnaire_welcome_page_right_paragraph_two:
    "By analyzing the data and correlations of your biological organism, it will be possible for your healthcare professional to clearly understand which care, prescription or recommendation is most effective for you at this time.",
  questionnaire_welcome_page_right_paragraph_three:
    "The analysis of your Sensory Activity will be based on a questionnaire, which will take approximately 15/20 minutes to complete. However, you can take as long as you need. For a successful analysis, it is essential that you take this time for yourself.",
  questionnaire_welcome_page_right_list_title:
    "Before you start, here's what you should know:",
  questionnaire_welcome_page_right_list_item_one:
    "Answer absolutely freely and intuitively, without hesitation.",
  questionnaire_welcome_page_right_list_item_two:
    "The important thing is not what they say or have said about you, but how you feel about yourself.",
  questionnaire_welcome_page_right_list_item_three:
    "Use your frankness and spontaneity to promote a reading that is perfectly in sync with your organic self. There are no right or wrong answers.",
  questionnaire_welcome_page_right_list_item_four:
    "The questionnaire must be completed in its entirety. You can't fill in one part now and another part later.",
  questionnaire_welcome_page_right_list_item_five:
    "If you hesitate about an answer, don't worry, we recommend that you simply move on to the next question.",
  questionnaire_welcome_page_right_footnote:
    "If you don't want to go any further: Click on the link you received by email to come back later. You have 30 days to complete the questionnaire from the date you signed up.",
  questionnaire_welcome_start_button_text: "Start analysis",
  questionnaire_completed_page_title: "Your analysis will be ready soon",
  questionnaire_completed_page_left_text:
    "And it will be sent to your email so that you can share it with your healthcare professional. For more information on the first analysis that decodes the Sensory System in real time, see ",
  questionnaire_completed_page_right_text:
    "Recommend care that is perfectly aligned with the true nature of each individual, starting with those you love the most. Any friend or family member can benefit from this analysis to take care of their health in a personalized way. Recommending is caring.",
  questionnaire_completed_page_submitted_text:
    "Your data has been successfully submitted.",
  // BACKEND ERROR MESSAGES
  EMAIL_NOT_FOUND: "Email not found. Please check again.",
  EMAIL_ALREADY_EXISTS: "There is already a user with this email",
  ACCESS_DENIED: "Access Denied",
  USER_NOT_FOUND:
    "User not found. \nPlease check if the email is correct or, alternatively, register.",
  close: "Close",
  success: "Success",
};

export default messages;
