import { addTranslations } from "../../../translations/language-provider/translations";
// todo - remove everything that comes from outside the lib folder(manage somehow)

const messages = {
  contentManagement: "Gestão de Conteúdos",
  customTexts: "Textos Personalizados",
  emailTemplates: "Modelos de Email",
  pacients: "Pacientes",
  questionnairesMenu: "Questionários",
  questionsMenu: "Perguntas",
  usersMenu: "Utilizadores",
  otherMenu: "Outros",
  answerOptions: "Opções de Resposta",
  dataBase: "Base de Dados",
  answersMenu: "Opções de Resposta",
  answerQuestionnaireMenu: "Responder Questionário",
  new: "Novo",
  criteriaMenu: "Critérios",
  criteriaUsageMenu: "Uso de Critérios",
  metaSkillMenu: "Meta Habilidades",
  principleMenu: "Princípios",
  algorithmMenu: "Algoritmo Principal",
  invitesMenu: "Convites",
  elementMenu: "Elementos",
  feelingsMenu: "Sentimentos",
  organsMenu: "Órgãos",
  outputMenu: "Outputs",
  attributesMenu: "Atributos",
  inputMenu: "Inputs",
  secondaryAlgorithmMenu: "Algoritmo Secundário",
  naturalElementMenu: "Elementos Naturais",
  seasonMenu: "Estações",
  characterMenu: "Caracteres",
  answerComponentMenu: "Componentes",
  mobileApkMenu: "Aplicações Móveis",
  criteriaManagementMenu: "Gestão de Critérios",
  apkMenu: "APK",
  textConfigMenu: "Configuração de Texto",
  algorithmOutputMenu: "Outputs de Algoritmo",
  generalOutputMenu: "Outputs Gerais",
  highlightMenu: "Destaques",
  highlightTypeMenu: "Tipos de Destaque",
  highlightOutputMenu: "Outputs",
  dailyChallengeMenu: "Desafio Diário",
  staticStringMenu: "Strings Estáticas",
  userBackofficeMenu: "Utilizadores de Backoffice",
  userAppMenu: "Utilizadores de APP",
  analysis: "Análises",
  editableValues: "Valores editáveis"
};

addTranslations("pt-PT", messages);

export default messages;
